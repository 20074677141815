<template>
  <button
      @click="handlerClick"
      :class="['button button--transparent', buttonClass]"
  >
    <span class="size14">
        {{ title || $t('Інші способи оплати') }}
    </span>
  </button>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { PAYMENT_METHOD } from "../../paymentMethod";

export default {
  name: 'DefaultPayButton',
  props: {
    actionData: {
      type: Object,
      require: true,
    },
    paymentMethod: {
      type: String,
      default: '',
    },
    title: {
      type: String,
      default: '',
    },
    buttonClass: {
      type: String,
      default: '',
    },
  },
  computed: {
    ...mapGetters({
      isDesktop: 'shared/device/isDesktop',
    }),
  },
  methods: {
    ...mapActions({
      makeOrder: 'others/makeOrder',
      createPaymentPrivat24: 'publication/createPaymentPrivat24',
    }),
    async handlerClick() {
      this.$emit('button:click');

      const billingWindow = this.isDesktop
        ? window.open('https://auto.ria.com/demo/partials/loader/wait.html', '_blank')
        : window;

      try {
        const orderResponse = await this.makeOrder(this.actionData);
        let successData = orderResponse;

        if (orderResponse?.orderId) {
          let orderLink = orderResponse?.orderLink;
          if (this.paymentMethod === PAYMENT_METHOD.PRYVAT_24) {
            const privatResponse = await this.createPaymentPrivat24({
              orderId: orderResponse?.orderId,
            });

            orderLink = privatResponse.paymentUrl;
            successData = {
              ...successData,
              paymentType: 115,
            };
          }

          billingWindow.location.href = orderLink;
        } else if (this.isDesktop) {
          billingWindow.window.close();
        }

        this.$emit('payment:success', successData);
      } catch (error) {
        if (this.isDesktop) billingWindow.window.close();

        this.$emit('payment:fail');
        console.error('Refill makeOrder error', error.message);
      }
    }
  }
}
</script>
<style scoped src="./index.sass" lang="sass" />
