export default {
  name: 'StaticContent',
  props: {
    data: Object,
  },
  computed: {
    mainData() {
      return this.data?.mainData || {};
    },
    price() {
      return this.data?.orderData?.price || {};
    },
  },
};
