<template>
  <button
      v-if="!isShowBtn"
      class="button button--transparent apple-pay"
      @click="handlerClick"
  >
    <span>
        Pay
    </span>
  </button>
</template>

<script>
import {mapActions} from "vuex";
import {PAYMENT_METHOD} from "../../paymentMethod";

export default {
  name: 'ApplePay',
  props: {
    actionData: {
      type: Object,
      default: null,
    },
  },
  computed: {
    isShowBtn() {
      return Boolean(window?.ApplePaySession?.canMakePayments());
    },
  },
  methods: {
    ...mapActions({
      makeOrder: 'others/makeOrder',
    }),
    handlerClick() {
      this.$emit('button:click');

      const session = new ApplePaySession(1, this.actionData.data);

      // Відправка сертифікату Merchant Identity Certificate на бекенд (запит на валідацію)
      session.onvalidatemerchant = ({validationURL}) => {
        const data = {
          validationURL,
          type: this.actionData.type,
          merchantIdentifier: this.actionData.data.merchantIdentifier,
        };

        this.startApplePaymentSession(data).then(({merchantSession}) => {
          session.completeMerchantValidation(merchantSession);
        }).catch(() => {
          session.abort();
        });
      };

      // Відправка запиту на оплату
      session.onpaymentauthorized = (event) => {
        const data = {
          services: this.actionData.services,
          paymentMethod: PAYMENT_METHOD.APPLE_PAY,
          paymentData: event.payment?.token?.paymentData || {},
          paymentTypeId: this.actionData?.paymentType,
          paymentCost: this.actionData?.sum,
        };

        this.makeOrder(data).then((response) => {
          if (response?.orderId) {
            session.completePayment(window.ApplePaySession.STATUS_SUCCESS);
            this.$emit('payment:success', response);
          } else {
            session.completePayment(window.ApplePaySession.STATUS_FAILURE);
            this.$emit('payment:fail');
          }
        }).catch((e) => {
          session.abort();
          this.$emit('payment:fail');
        });
      };

      session.begin();
    },
  },
};
</script>
<style scoped src="./index.sass" lang="sass" />
