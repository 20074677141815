<template>
  <div ref="googlePay"/>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { PAYMENT_METHOD } from "../../paymentMethod";

export default {
  name: 'GooglePay',
  props: {
    actionData: {
      type: Object,
      default: null,
    },
  },
  computed: {
    ...mapGetters({
      isDesktop: 'shared/device/isDesktop',
    }),
  },
  methods: {
    ...mapActions({
      makeOrder: 'others/makeOrder',
    }),
    loadGooglePay() {
      if (!window.googlePayScriptLoaded) return;

      const { parameters = {}, type = {} } = this.actionData?.data?.baseCardPaymentMethod || {};

      const baseCardPaymentMethod = () => ({
        parameters: {
          // allowedCardAuthMethods потрібно переіменувати
          allowedAuthMethods: parameters?.allowedCardAuthMethods,
          allowedCardNetworks: parameters?.allowedCardNetworks,
        },
        type,
      });

      const cardPaymentMethod = () => ({
        ...baseCardPaymentMethod(),
        tokenizationSpecification: this.actionData?.data?.tokenizationSpecification,
      });

      const getGoogleIsReadyToPayRequest = () => ({
        ...this.actionData?.data?.baseRequest,
        allowedPaymentMethods: [baseCardPaymentMethod()],
      });

      const getGoogleTransactionInfo = () => ({
        ...this.actionData?.data?.transactionInfo,
        totalPrice: this.actionData?.data?.transactionInfo?.totalPrice.toString(),
      });

      const getGooglePaymentDataRequest = () => {
        const paymentDataRequest = { ...this.actionData?.data?.baseRequest };
        paymentDataRequest.allowedPaymentMethods = [cardPaymentMethod()];
        paymentDataRequest.transactionInfo = getGoogleTransactionInfo();
        paymentDataRequest.merchantInfo = this.actionData?.data?.merchantInfo;
        return paymentDataRequest;
      };

      const mode = process.env.NODE_ENV;
      const isProduction = mode === 'production';
      let paymentsClient = null;

      const getGooglePaymentsClient = () => {
        if (paymentsClient === null) {
          paymentsClient = new google.payments.api.PaymentsClient({
            // Alterar o environment para 'PRODUCTION' em prod
            environment: isProduction ? 'PRODUCTION' : 'TEST',
          });
        }
        return paymentsClient;
      };

      const processPayment = (paymentData) => {
        const paymentToken = paymentData?.paymentMethodData?.tokenizationData?.token;
        return Buffer.from(paymentToken).toString('base64');
      };

      const onGooglePaymentButtonClicked = () => {
        this.$emit('button:click');

        const paymentDataRequest = getGooglePaymentDataRequest();
        paymentDataRequest.transactionInfo = getGoogleTransactionInfo();

        const gPaymentsClient = getGooglePaymentsClient();
        gPaymentsClient
          .loadPaymentData(paymentDataRequest)
          .then((paymentData) => {
            // handle the response
            const paymentToken = processPayment(paymentData);
            if (paymentToken) {
              const data = {
                services: this.actionData?.services,
                paymentMethod: PAYMENT_METHOD.GOOGLE_PAY,
                paymentData: paymentToken,
                paymentTypeId: this.actionData?.paymentType,
                paymentCost: this.actionData?.sum,
              };

              return this.makeOrder
                .request({ data })
                .then((response) => {
                  if (response?.payData?.completeData?.redirect_url) {
                    this.$emit('payment:success', response);
                    if (this.isDesktop) {
                      const billingWindow = window.open('https://auto.ria.com/demo/partials/loader/wait.html', '_blank', 'fullscreen=no,height=768,width=1024,toolbar=no,scrollbars=yes');
                      billingWindow.location.href = response?.payData?.completeData?.redirect_url;
                    } else {
                      window.location = response?.payData?.completeData?.redirect_url;
                    }
                  }
                  return response;
                }).catch((e) => {
                  console.error('makeOrder', e.message);
                  this.$emit('payment:fail');
                });
            }
          }).catch((err) => {
            // show error in developer console for debugging
            console.error(err);
            this.$emit('payment:fail');
          });
      };

      const addGooglePayButton = () => {
        const gPaymentsClient = getGooglePaymentsClient();
        const btnParams = {
          buttonColor: 'default',
          buttonType: 'buy',
          buttonLocale: 'uk',
          buttonSizeMode: 'fill',
          buttonRadius: 5,
        };
        const button = gPaymentsClient.createButton({
          ...btnParams,
          onClick: onGooglePaymentButtonClicked,
        });

        this.$refs.googlePay.appendChild(button);
      };

      const onGooglePayLoaded = () => {
        const gPaymentsClient = getGooglePaymentsClient();
        gPaymentsClient.isReadyToPay(getGoogleIsReadyToPayRequest())
          .then((response) => {
            if (response.result) addGooglePayButton();
          }).catch((err) => {
            console.error(err);
          });
      };

      onGooglePayLoaded();
    },
    initGoogleScript() {
      const canMakeApplePayment = Boolean(window?.ApplePaySession?.canMakePayments());

      if (!canMakeApplePayment && !window.googlePayScriptLoaded) {
        const googlePayScript = document.createElement('script');
        googlePayScript.src = 'https://pay.google.com/gp/p/js/pay.js';
        googlePayScript.async = true;
        googlePayScript.onload = () => {
          window.googlePayScriptLoaded = true;
        };
        googlePayScript.onerror = (error) => {
          console.error('Error loading Google Pay script:', error);
        };
        document.head.appendChild(googlePayScript);
      }
    }
  },
  beforeMount() {
    this.initGoogleScript();
  },
  mounted() {
    setTimeout(() => this.loadGooglePay(), 500);
  },
};
</script>
<style src="./index.sass" lang="sass" />
