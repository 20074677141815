<template>
  <DefaultPayButton
    :paymentMethod="paymentMethod"
    :actionData="actionData"
    title="Pay"
    buttonClass="privat-pay"
    @payment:success="data => $emit('payment:success', data)"
    @button:click="$emit('button:click')"
  />
</template>

<script>
import { PAYMENT_METHOD } from "../../paymentMethod";

export default {
  name: 'Pryvat24',
  computed: {
    paymentMethod() {
      return PAYMENT_METHOD.PRYVAT_24;
    }
  },
  components: {
    DefaultPayButton: () => import('../DefaultPayButton/index.vue'),
  },
  props: {
    actionData: {
      type: Object,
      required: true,
    },
  },
}
</script>
<style src="./index.sass" lang="sass" />
