export const PAY_BUTTONS = {
  APPLE: 'aPay',
  GOOGLE: 'gPay',
  PRYVAT: 'p24Pay',
  DEFAULT: 'defaultPay',
};

export default {
  name: 'UpdateStatisticsPopup',
  components: {
    [PAY_BUTTONS.APPLE]: () => import('../buttons/ApplePay/index.vue'),
    [PAY_BUTTONS.GOOGLE]: () => import('../buttons/GooglePay/index.vue'),
    [PAY_BUTTONS.PRYVAT]: () => import('../buttons/Pryvat24/index.vue'),
    [PAY_BUTTONS.DEFAULT]: () => import('../buttons/DefaultPayButton/index.vue'),
  },
  props: {
    buttonsData: {
      type: Object,
      required: true,
    },
  },
  methods: {
    isExistButtonName(name) {
      return Object.values(PAY_BUTTONS).some((btnName) => btnName === name);
    },
  },
};
