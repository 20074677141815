export const SEGMENT = {
  C2C: 'c2c',
  C2C_NO_ADS: 'c2cNoAds',
  B2C: 'b2c',
  SMB1_2: 'smb1-2',
  SMB3_PLUS: 'smb3+',
  B2C_AUTO_DEALER: 'b2cAutoDealer',
  B2C_AUTO_SALON: 'b2cAutoSalon',
  B2C_AUTO_SALE: 'b2cAutoSale',
  B2C_AUTO_ORDER_CALLS: 'b2cAutoOrder_calls',
  B2C_AUTO_DEALER_COMP_EXP: 'b2cAutoDealer_comp_exp',
  B2C_AUTO_DISASSEMBLE: 'b2cAutoDisassemble',
  B2C_AUTO_ORDER: 'b2cAutoOrder',
  B2C_AUTO_ORDER_EXP: 'b2cAutoOrder_exp',
  B2C_AUTO_DEALER_EXP: 'b2cAutoDealer_exp',
};

export const SEGMENT_WITH_SWITCH_PHONE = ['b2cAutoDealer', 'b2cAutoOrder', 'b2cAutoSalon'];
